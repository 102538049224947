import React, { useEffect, createContext, useState, useRef } from 'react';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export const CookieContext = createContext(null);

const CookieProvider = ({ children }) => {
    useEffect(() => {
        CookieConsent.run({
            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true
                }
            },
            language: {
                default: 'de',
                translations: {
                    de: {
                        consentModal: {
                            title: "Wir verwenden Cookies 🍪",
                            description: "Wir nutzen Cookies, um Ihnen die beste Nutzererfahrung auf unserer Website zu ermöglichen. Alle von uns verwendeten Cookies sind technisch notwendig.",
                            acceptAllBtn: "Alle akzeptieren",
                            showPreferencesBtn: "Weitere Informationen"
                        },
                        preferencesModal: {
                            title: "Cookie-Einstellungen verwalten",
                            acceptAllBtn: "Alle akzeptieren",
                            acceptNecessaryBtn: "Nur notwendige akzeptieren",
                            savePreferencesBtn: "Auswahl speichern",
                            closeIconLabel: "Schließen",
                            sections: [
                                {
                                    title: "Weitere Informationen",
                                    description: "Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer Datenschutzerklärung."
                                },
                                {
                                    title: "Technisch notwendige Cookies",
                                    description: "Diese Cookies sind unerlässlich für den Betrieb unserer Website. Sie ermöglichen grundlegende Funktionen wie die Speicherung Ihres Login-Tokens und die Sicherheit der Website.",
                                    linkedCategory: "necessary"
                                }
                            ]
                        }
                    }
                }
            },
           
        });
    }, []);

    return (
        <></>
    );
};

export default CookieProvider;
